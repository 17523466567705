.r-segmented-control {
    width: 100%;
    background-color: #fff;    
    border-bottom: 1px solid #e9eff3;
    border-left: 1px solid #fff;
    text-align: center;
    height: 38px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    direction: ltr;
    display: table;
}

.r-segmented-control > ul {
    display: table-row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 8px;
}

.r-segmented-control > ul > li {
    background: #fff;
    border: 1px solid;    
    border-right: none;
    font-size: 13px;
    height: 26px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    display: table-cell;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    -ms-flex: auto;
    flex: auto;
}

.r-segmented-control > ul > li:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.r-segmented-control > ul > li:last-of-type {
    border-right: 1px solid;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.r-segmented-control > ul > li[aria-disabled="true"] {
    border-color: #ccc !important;
    color: #ccc !important;
    cursor: not-allowed;
}

.r-segmented-control > ul > li.base {
    border-color: rgb(0,122,255) !important;
    color: rgb(0,122,255);
    background: #fff !important;
}

.r-segmented-control > ul > li.dark {
    border-color: #333 !important;
    color: #333;
    background: #fff !important;
}

.r-segmented-control > ul > li.light {
    border-color: rgb(247, 247, 247) !important;
    color: rgb(247, 247, 247);
    background: transparent !important;
}

.r-segmented-control > ul > li.success {
    border-color: #5cb85c !important;
    color: #5cb85c;
    background: #fff !important;
}

.r-segmented-control > ul > li.error {
    border-color: #d9534f !important;
    color: #d9534f;
    background: #fff !important;
}

.r-segmented-control > ul > li.base.selected {
    background: rgb(0,122,255) !important;    
    color: #fff !important;
}

.r-segmented-control > ul > li.dark.selected {
    background: #333 !important;
    color: #fff !important;
}

.r-segmented-control > ul > li.light.selected {
    background: rgb(247, 247, 247) !important;
    color: #333 !important;
}

.r-segmented-control > ul > li.success.selected {
    background: #5cb85c !important; 
    color: #fff !important;
}

.r-segmented-control > ul > li.error.selected {
    background: #d9534f !important;
    color: #fff !important;
}

.rsc-warning {

}