.react-calendar { width: 100%;}

.available {
    background-color: green;
    color: black;
 }

.selected {
    background-color: #aaaaaa;
    color: black;
    }
.available_day_by_day {
    background-color: #007fff;
    color: black;

}

.booked {
    background-color: red;
    color: black;

 }